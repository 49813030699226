import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  logo: {
    height: theme.spacing(4),
    width: "auto",
    cursor: "pointer",
  },
  marignTop: 24,
}))

const Logo = ({ onClick, variant }) => {
  const classes = useStyles()

  return <Box component="span" m={1}></Box>
}

export default Logo
