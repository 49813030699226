/* eslint-disable react/prop-types */
import React from "react"
import { Grid, makeStyles, Hidden } from "@material-ui/core"
import Logo from "./Logo"
import "./styles.css"
import logo from "../assets/logo-dark.png" // Adjust the import path as necessary

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100vh",
    alignItems: "center",
    background: "#020C1C",
  },
  logo: {
    width: 200,
  },
}))

const AuthPage = ({ content }) => {
  const classes = useStyles()
  const ContentComponent = content
  return (
    <Grid container>
      <Hidden smDown>
        <Grid item md={6}>
          <Grid
            className={classes.root}
            container
            direction="column"
            justify="center"
            align="center"
          >
            <img src={logo} alt="Alchtec.com" className={classes.logo} />
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6}>
        <ContentComponent />
      </Grid>
    </Grid>
  )
}

export default AuthPage
